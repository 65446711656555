// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1em 0;
  text-align: center;
}

nav button {
  background: none;
  border: none;
  color: #fff;
  padding: 1em;
  cursor: pointer;
}

nav button:hover, nav button.active {
  background-color: #555;
}

main {
  padding: 1em;
}

section {
  display: none;
}

section.active {
  display: block;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 2em 0;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 1em;
  text-align: left;
}

th {
  background-color: #333;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: #f4f4f4;\n}\n\nheader {\n  background-color: #333;\n  color: #fff;\n  padding: 1em 0;\n  text-align: center;\n}\n\nnav button {\n  background: none;\n  border: none;\n  color: #fff;\n  padding: 1em;\n  cursor: pointer;\n}\n\nnav button:hover, nav button.active {\n  background-color: #555;\n}\n\nmain {\n  padding: 1em;\n}\n\nsection {\n  display: none;\n}\n\nsection.active {\n  display: block;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin: 2em 0;\n}\n\ntable, th, td {\n  border: 1px solid #ddd;\n}\n\nth, td {\n  padding: 1em;\n  text-align: left;\n}\n\nth {\n  background-color: #333;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
